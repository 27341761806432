// Remove `no-js` class
// eslint-disable-next-line import/no-duplicates
import $ from 'jquery'
import slick from 'slick-carousel'
import gsap from 'gsap/dist/gsap'
import Textify from 'textify.js'
import ScrollTrigger from 'gsap/dist/ScrollTrigger'
import CSSRulePlugin from 'gsap/dist/CSSRulePlugin'

// eslint-disable-next-line no-new
new Textify(
  {
    el: '.text-animation',
    animation: {
      stagger: 0.025,
      duration: 0.7,
      ease: 'expo.inOut',
      animateProps: { opacity: 0, scale: 0 }
    }
  },
  gsap
)

// eslint-disable-next-line no-new
new Textify(
  {
    el: '.large-text-animation',
    largeText: true,
    splitType: 'lines',
    animation: {
      by: 'lines',
      duration: 0.7,
      ease: 'power3.inOut',
      stagger: 0.1
    }
  },
  gsap
)

document.addEventListener('DOMContentLoaded', function () {
  // eslint-disable-next-line no-new
  new Textify(
    {
      el: '.large-text-animation-p p',
      largeText: true,
      splitType: 'lines',
      animation: {
        by: 'lines',
        duration: 0.7,
        ease: 'power3.inOut',
        stagger: 0.1
      }
    },
    gsap
  )
})

window.jQuery = $
window.slick = slick
document.documentElement.classList.remove('no-js')

// Add your codes here

$(function () {
  $('.js-input').keyup(function () {
    if ($(this).val()) {
      $(this).addClass('not-empty')
    } else {
      $(this).removeClass('not-empty')
    }
  })

  $(window).on('scroll', function () {
    if ($(window).scrollTop() > 50) {
      $('header').addClass('active')
    } else {
      $('header').removeClass('active')
    }
  })

  window.onload = function () {
    $('.button_container').click(function () {
      $('.button_container').toggleClass('active')
      $('.overlay').toggleClass('open')
      $('body').toggleClass('active')
    })
  }
})

// eslint-disable-next-line no-undef
gsap.registerPlugin(ScrollTrigger)

const revealContainers = document.querySelectorAll('.img-container')

// eslint-disable-next-line no-undef
const rule = CSSRulePlugin.getRule('.img-container:after')

revealContainers.forEach((container) => {
  const image = container.querySelector('img')

  // eslint-disable-next-line no-undef
  const tl = gsap.timeline({
    scrollTrigger: {
      trigger: container,
      toggleActions: 'restart none none reset',
      once: true
    }
  })

  tl.to('body', { duration: 0.5, autoAlpha: 1 })
    .to(rule, { duration: 1, height: '100%', ease: 'Power2.ease' })
    .to(rule, { duration: 1, height: '0%', ease: 'Power2.ease' })
    .set(rule, { duration: 0, right: 0, top: 'unset' })
    .to(image, { duration: 0.2, opacity: 1, delay: -1 })
    .from(image, {
      duration: 1,
      scale: 1.4,
      ease: 'Power2.easeInOut',
      delay: -1.2
    })
})

function Marquee(selector, speed) {
  const parentSelector = document.querySelector(selector)
  const clone = parentSelector.innerHTML
  const firstElement = parentSelector.children[0]
  let i = 0
  parentSelector.insertAdjacentHTML('beforeend', clone)
  parentSelector.insertAdjacentHTML('beforeend', clone)

  setInterval(function () {
    firstElement.style.marginLeft = `-${i}px`
    if (i > firstElement.clientWidth) {
      i = 0
    }
    i = i + speed
  }, 0)
}

window.addEventListener('load', Marquee('.marquee', 0.2))
